import { fetchWithRetry } from '@palqee/utils'

export enum MimeTypesToExtension {
  'application/pdf' = '.pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = '.docx',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation' = '.pptx',
  'application/msword' = '.doc',
  'application/vnd.ms-powerpoint' = '.ppt',
  'application/vnd.ms-excel' = '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = '.xlsx',
  'image/png' = '.png',
  'image/jpeg' = '.jpeg',
  'text/plain' = '.txt',
  'text/csv' = '.csv',
  'text/html' = '.html',
}

export enum MimeTypes {
  'APPLICATION_PDF' = 'application/pdf',
  'APPLICATION_DOCX' = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'APPLICATION_PPTX' = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'APPLICATION_DOC' = 'application/msword',
  'APPLICATION_PPT' = 'application/vnd.ms-powerpoint',
  'APPLICATION_XLSX' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'APPLICATION_XLS' = 'application/vnd.ms-excel',
  'IMAGE_PNG' = 'image/png',
  'IMAGE_JPEG' = 'image/jpeg',
  'TEXT_PLAIN' = 'text/plain',
  'TEXT_CSV' = 'text/csv',
  'TEXT_HTML' = 'text/html',
}

export const base64ToBlob = (
  base64: string,
  type = 'application/octet-stream',
) => fetchWithRetry(`data:${type};base64,${base64}`).then((res) => res.blob())

export const toBase64 = (data: string, type: string) => {
  return `data:${type};base64,${data}`
}

export const downloadFile = (base64File, filename) => {
  const link = document.createElement('a')
  link.href = base64File
  link.download = filename || 'download'

  link.click()
  return link
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const blobToBase64 = (blob) => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

export const convertWebpToJpg = (blob) => {
  const oFileReader = new FileReader()
  oFileReader.readAsDataURL(blob)

  return new Promise((resolve) => {
    oFileReader.onloadend = function (e) {
      // Create a new Image Obj
      const newImg = new Image()
      // Set crossOrigin Anonymous (That's important, otherwise it will not be read)
      newImg.crossOrigin = 'Anonymous'
      newImg.onload = function () {
        // Create a new Canvas
        const canvas = document.createElement('canvas') as any
        // Set 2D context
        const context = canvas.getContext('2d')
        // Set crossOrigin Anonymous (That's important, otherwise it will not be read)
        canvas.crossOrigin = 'anonymous'
        // Set Width/Height
        canvas.width = newImg.width
        canvas.height = newImg.height
        // Start
        context.drawImage(newImg, 0, 0)

        resolve(canvas.toDataURL('image/jpeg'))
      }
      // Load Webp Base64
      newImg.src = String(e.target.result)
    }
  })

  // return imageSrc
}
