import { useCallback, useEffect } from 'react'

import { useRouter } from 'next/router'

interface useHubspotChatOptions {
  /*
    When set to true the hubspot chat widget will be removed
   */
  remove?: boolean
}

export const useHubspotChat = ({
  remove = false,
}: useHubspotChatOptions = {}) => {
  const router = useRouter()

  const buildUrlQuery = useCallback(() => {
    if (remove || !router.isReady) return

    const hubspotQuery = router?.locale

    if (router.query.lang !== hubspotQuery) {
      router.push(
        {
          query: { ...router.query, lang: hubspotQuery },
        },
        undefined,
        { shallow: true },
      )
      return
    }
  }, [remove, router])

  useEffect(buildUrlQuery, [buildUrlQuery])

  useEffect(() => {
    if (remove) {
      window?.HubSpotConversations?.widget?.remove()
    }
  }, [remove])
}
