import { captureException } from '@sentry/nextjs'
import { useAuthLoginIdentityApplicationsGetLazyQuery } from './__generated__'

export const useCompanySso = () => {
  const [getAuthIdentityApplications] =
    useAuthLoginIdentityApplicationsGetLazyQuery()

  const getCompanyIdentityApplicationBySsoKey = async (
    primaryEmailDomain: string,
    options: Parameters<typeof getAuthIdentityApplications>[0],
  ) => {
    try {
      const response = await getAuthIdentityApplications({
        variables: {
          input: {
            filter: {
              companyUsersPrimaryEmailDomain: primaryEmailDomain,
            },
          },
        },
        ...options,
      })
      return response
    } catch (error) {
      captureException(error)
    }
  }

  return {
    handlers: {
      getCompanyIdentityApplicationBySsoKey,
    },
  }
}
