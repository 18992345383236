import { AuthIdentityApplication } from '@generated/types.d'

export type IdentityApplication = Pick<
  AuthIdentityApplication,
  'identityProviderCode' | 'uriInit'
>

export enum DataRegionEnum {
  eu_west_1 = 'palqee.com',
  sa_east_1 = 'palqee.com.br',
}

export type DataRegion = {
  label: string
  value: `${DataRegionEnum}`
}

export interface LoginFormInterface {
  email: string
  password?: string
  rememberMe: boolean
  dataRegion: DataRegion
  identityApplication: IdentityApplication
}
