import {
  onErrorHandler,
  validationFields,
} from '@features/auth/components/login/validation'
import { GraphQLExceptionCode } from '@features/core/graphql/graphql-errors'
import { captureException } from '@sentry/nextjs'
import { LoginProps, useAuthFlow } from '@features/auth'
import { useCompanySso } from '@features/auth/hooks/use-company-sso'
import { useFormValidation } from '@common/utils/use-form-validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useIntl } from '@palqee/intl'
import { DataRegionEnum, LoginFormInterface } from './types'
import { AuthIdentityProviderCodeEnum } from '@generated/types.d'
import { getMainDomain } from '@common/utils/url'

export const useLogin = (props: LoginProps) => {
  const intl = useIntl()

  const [loginLoading, setLoginLoading] = useState(false)
  const [showDataRegionAndError, setShowDataRegionAndError] = useState(false)

  const { yupSchema } = useFormValidation({
    props: {
      intl,
    },
    validationFields,
  })

  const dataRegionOptions = [
    {
      label: intl.formatMessage({
        id: 'login.form.data_region.ireland',
        defaultMessage: 'Ireland',
      }),
      value: DataRegionEnum.eu_west_1,
    },
    {
      label: intl.formatMessage({
        id: 'login.form.data_region.brazil',
        defaultMessage: 'Brazil',
      }),
      value: DataRegionEnum.sa_east_1,
    },
  ]

  const getDataRegionByDomain = () => {
    if (typeof window !== 'undefined') {
      const domain = getMainDomain(window.location.href)
      if (domain !== 'localhost') {
        return (
          dataRegionOptions.find((dataRegion) => dataRegion.value === domain) ??
          dataRegionOptions[0]
        )
      }
    }

    return dataRegionOptions[0]
  }

  const methods = useForm<LoginFormInterface>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    values: {
      email: '',
      password: '',
      dataRegion: getDataRegionByDomain(),
      rememberMe: props?.rememberMe ?? true,
      identityApplication: props?.identityApplication ?? {
        identityProviderCode: null,
        uriInit: '',
      },
    },
  })

  const { setError, setValue, getValues } = methods
  const { onLogin } = useAuthFlow()

  const [companyLogo, setCompanyLogo] = useState(null)
  const [companyName, setCompanyName] = useState('')

  const {
    handlers: { getCompanyIdentityApplicationBySsoKey },
  } = useCompanySso()

  useEffect(() => {
    if (props?.company) {
      setCompanyName(props?.company?.name)
      setCompanyLogo(props?.company?.logo?.publicUrl)
    }
  }, [props?.company])

  const onCheckCompanyIdentityApplication = async (
    formData: LoginFormInterface,
  ) => {
    setShowDataRegionAndError(false)
    setLoginLoading(true)

    // get email domain
    const emailDomain = formData?.email.substr(
      formData?.email.indexOf('@') + 1,
      formData?.email.length - 1,
    )

    try {
      const response = await getCompanyIdentityApplicationBySsoKey(
        emailDomain,
        {
          fetchPolicy: 'network-only',
        },
      )

      setLoginLoading(false)

      if (
        response?.data?.auth?.identityApplicationsGet?.identityApplications
          .length === 0
      ) {
        // if no identityApplication found then not set by user
        // then just use cognito form
        setValue('identityApplication', {
          identityProviderCode: AuthIdentityProviderCodeEnum.AwsCognito,
        })
      } else {
        const company =
          response?.data?.auth?.identityApplicationsGet?.companies?.[0]

        setCompanyName(company?.name)
        setCompanyLogo(company?.logo?.publicUrl)

        // currently we should only ever have one
        setValue(
          'identityApplication',
          response?.data?.auth?.identityApplicationsGet
            ?.identityApplications?.[0],
        )
      }
    } catch (error) {
      captureException(error)
    }
  }

  const onSubmitAwsCognitoApplicationForm = async (
    formData: LoginFormInterface,
  ) => {
    setLoginLoading(true)

    try {
      await onLogin(formData)
      setShowDataRegionAndError(false)
      setLoginLoading(false)
    } catch (error) {
      // show data region dropdown
      // and message
      setShowDataRegionAndError(true)
      setError('dataRegion', {
        type: 'IncorrectDataRegion',
        message: intl.formatMessage({
          id: 'login.error.data_region',
          defaultMessage:
            'If still unable to login please try different Data region',
        }),
      })
      // currently only support these two
      // error codes anything else just default
      // to error page
      if (
        error === GraphQLExceptionCode.NotAuthorizedException ||
        error === GraphQLExceptionCode.AuthLoginWrongCredentials
      ) {
        return onErrorHandler({ intl, setError, setLoginLoading })(error)
      }
    }
  }

  const onSubmitSsoForm = async () => {
    setLoginLoading(true)

    if (getValues('identityApplication').uriInit)
      window.location.href = getValues('identityApplication').uriInit
  }

  const handlers = {
    onSubmitSsoForm,
    onSubmitAwsCognitoApplicationForm,
    onCheckCompanyIdentityApplication,
  }

  const get = {
    showDataRegionAndError,
    loginLoading,
    companyLogo,
    companyName,
    dataRegionOptions,
  }

  return {
    get,
    methods,
    handlers,
  }
}
