import * as Types from '../../../../../__generated__/types.d'

import { gql } from '@apollo/client'
export type CompanyBasicWithSlugFragment = {
  __typename: 'Company'
  id: string
  slug: string
  usersPrimaryEmailDomain?: string | null
}

export type IdentityApplicationsGetMainFragment = {
  __typename: 'AuthIdentityApplicationsGetPayload'
  companies: Array<{
    __typename: 'Company'
    id: string
    slug: string
    usersPrimaryEmailDomain?: string | null
  }>
  identityApplications: Array<{
    __typename: 'AuthIdentityApplication'
    id: string
    identityProviderCode: Types.AuthIdentityProviderCodeEnum
    name: string
    uriInit?: any | null
    uriLogout?: any | null
    uriProviderRedirect?: any | null
    specificConfig?: any | null
  }>
}

export type LoginIdentityApplicationsGetFragment = {
  __typename: 'AuthIdentityApplicationsGetPayload'
  companies: Array<{
    __typename: 'Company'
    name?: string | null
    logo?: {
      __typename: 'BinaryFileInfoPublic'
      publicUrl?: string | null
    } | null
  }>
  identityApplications: Array<{
    __typename: 'AuthIdentityApplication'
    identityProviderCode: Types.AuthIdentityProviderCodeEnum
    uriInit?: any | null
  }>
}

export const CompanyBasicWithSlugFragmentDoc = gql`
  fragment CompanyBasicWithSlug on Company {
    id
    slug
    usersPrimaryEmailDomain
  }
`
export const IdentityApplicationsGetMainFragmentDoc = gql`
  fragment IdentityApplicationsGetMain on AuthIdentityApplicationsGetPayload {
    companies {
      ...CompanyBasicWithSlug
    }
    identityApplications {
      id
      identityProviderCode
      name
      uriInit
      uriLogout
      uriProviderRedirect
      specificConfig
    }
  }
  ${CompanyBasicWithSlugFragmentDoc}
`
export const LoginIdentityApplicationsGetFragmentDoc = gql`
  fragment LoginIdentityApplicationsGet on AuthIdentityApplicationsGetPayload {
    companies {
      name
      logo {
        publicUrl
      }
    }
    identityApplications {
      identityProviderCode
      uriInit
    }
  }
`
export const namedOperations = {
  Fragment: {
    CompanyBasicWithSlug: 'CompanyBasicWithSlug',
    IdentityApplicationsGetMain: 'IdentityApplicationsGetMain',
    LoginIdentityApplicationsGet: 'LoginIdentityApplicationsGet',
  },
}
