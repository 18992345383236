import * as styleVars from '@common/styles/constants'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const CardStyle = css`
  box-shadow: 0 2px 24px 0 rgba(143, 150, 169, 0.15);
`

export const CardContentStyle = css`
  display: block;
  width: 100%;
  height: 100%;
`

export const CardContentInnerWrapper = styled.div`
  width: 100%;
  padding: 56px 64px 70px 64px;
  min-width: 320px;
  margin: 0 auto;

  @media (min-width: ${styleVars.breakPoint3Xl}) {
    padding: 96px 104px 112px 104px;
  }

  @media (max-width: ${styleVars.breakPointXl}) {
    padding: 30px 64px 30px 64px;
  }

  @media (max-width: ${styleVars.breakPointMd}) {
    padding: 36px 28px 45px 28px;
  }
`
