import { blobToBase64 } from './file'

export const getParamsFromUrl = <T>(): T => {
  try {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop as any),
    })

    return params as unknown as T
  } catch (error) {
    console.error('[getParamsFromUrl]', error)
  }
}

export const convertImageUrlToBase64 = async (url: string) => {
  try {
    const result = await fetch(url)
      .then((r) => r.blob())
      .then(blobToBase64)
      .then((data) => {
        return data
      })
    return result
  } catch (error) {
    console.error(error)
  }
}

export const getMainDomain = (url: string) => {
  try {
    // Parse the URL
    const urlObj = new URL(url)

    // Extract the hostname
    const hostname = urlObj.hostname

    // Split the hostname into parts
    const hostnameParts = hostname.split('.')

    // If the hostname has only two parts, it is already the main domain
    if (hostnameParts.length <= 2) {
      return hostname
    }

    // Function to determine if a part is a TLD
    const isTld = (part) => {
      return (
        part.length <= 3 ||
        ['com', 'org', 'net', 'gov', 'edu', 'co', 'io'].includes(part)
      )
    }

    // Check if the last part is a TLD
    const lastPart = hostnameParts[hostnameParts.length - 1]
    const secondToLastPart = hostnameParts[hostnameParts.length - 2]

    if (isTld(lastPart)) {
      // If the last part is a TLD, check the second to last part
      if (isTld(secondToLastPart)) {
        // If both are TLDs (e.g., .com.br), return the last three parts
        return hostnameParts.slice(-3).join('.')
      } else {
        // Otherwise, return the last two parts
        return hostnameParts.slice(-2).join('.')
      }
    } else {
      // If the last part is not a TLD, assume it is part of the domain name
      return hostnameParts.slice(-2).join('.')
    }
  } catch (error) {
    console.error('Invalid URL provided:', error)
    return null
  }
}

export const replaceDomain = (url: string, newDomain: string) => {
  try {
    // Parse the URL
    const urlObj = new URL(url)

    // Extract the subdomain (if any) and the main domain
    const hostnameParts = urlObj.hostname.split('.')

    if (hostnameParts.length > 2) {
      // Handle case with subdomain
      const domain = [hostnameParts[0], newDomain].join('.')
      urlObj.hostname = domain
    } else {
      // Handle case without subdomain
      urlObj.hostname = newDomain
    }

    // Return the modified URL as a string
    return urlObj.toString()
  } catch (error) {
    console.error('Invalid URL provided:', error)
    return null
  }
}
