import { Controller, useFormContext } from 'react-hook-form'
import { Form as FormV1 } from '@common/components/form'
import { useIntl } from '@palqee/intl'
import { replaceDomain } from '@common/utils/url'
import { Dispatch, SetStateAction } from 'react'

const SelectDataRegion = (props: {
  dataRegionOptions: { label: string; value: string }[]
  setDisableLogin: Dispatch<SetStateAction<boolean>>
}) => {
  const intl = useIntl()
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const onChangeHandler = async (selected) => {
    props.setDisableLogin(true)
    const newUrl = replaceDomain(window.location.href, selected.value)
    window.location.href = newUrl
  }

  return (
    <div className="pq-mb-1">
      <Controller
        name="dataRegion"
        render={({ field, field: { onChange } }) => {
          return (
            <FormV1.PqSelect
              {...field}
              options={props?.dataRegionOptions}
              caption={intl.formatMessage({
                id: 'login.form.label.data_region',
                defaultMessage: 'Data region',
              })}
              onChange={async (selected) => {
                onChange(selected)
                await onChangeHandler(selected)
              }}
              errorBoard
              errors={errors}
            />
          )
        }}
        control={control}
      />
    </div>
  )
}

export { SelectDataRegion }
